@tailwind base;
@tailwind components;
@tailwind utilities;

.font-pacifico {
    font-family: 'Pacifico', cursive;
}

.font-courgette {
    font-family: 'Courgette', cursive;
}

.font-gluten {
    font-family: 'Gluten', cursive;
}

.font-marker {
    font-family: 'Permanent Marker', cursive;
}

.font-ad {
    font-family: 'Architects Daughter', cursive;
}

.font-gh {
    font-family: 'Gloria Hallelujah', cursive;
}

.font-ss {
    font-family: 'Short Stack', cursive;
}

/*.svg-thumbnail path {*/
/*    stroke: black;*/
/*    stroke-width: 4;*/
/*    fill: white;*/
/*}*/

.svg-thumbnail path{
    stroke-width: 4;
    stroke-opacity: 1;
    fill: white;
}

.svg-thumbnail{
    border-radius: 0.2rem;
    border: 1px solid #D1D5DB;
}


.svg-thumbnail rect {
    width: 0px;
    height: 0px;
}


.svg-search-input {
    padding: 0.5rem;
    border-radius: 0.75rem;
    border: 1px solid #D1D5DB;
    font-family: 'Gluten', cursive;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 200px;
}

/* Apply to all scrollbars within the app */
*::-webkit-scrollbar {
    width: 15px;
}

*::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}
